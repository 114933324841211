.header {
  margin-top: 48px;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 28px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline;
  color: #c9179a;
  font-weight: bold;
  font-size: 1.3rem;
}

.nav-links a,
.nav-links .link {
  text-decoration: none;
  color: #c9179a;
  font-weight: bold;
  font-size: 1.3rem;
}

.nav-links a:hover,
.nav-links .link:hover {
  color: #90106e;
}

.nav-links .active {
  text-decoration: underline;
}
