.news-section {
  background-color: #242f6e;
  color: #f0e5c8;
  padding: 28px;
  margin: 40px 0;
}

.news-section h2 {
  margin: 0 0 12px 0;
  font-size: 1.5rem;
}

.news-section p {
  margin: 0;
  font-size: 1.1rem;
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
