@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #f0e5c8;
  font-family: "Inria Sans", sans-serif;
}

.Home {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 50%;
  margin: 0 auto;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  color: #9e7d22;
  margin-bottom: 80px;
  margin-top: 80px;
}

@media (max-width: 768px) {
  .Home {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .Home {
    max-width: 100%;
    margin: 0 20px 40px 20px;
  }
}
