.research-section {
  margin: 40px 0;
}

.research-section h2 {
  font-size: 2rem;
  margin: 0 0 20px 0;
  color: #242f6e;
  font-weight: bold;
}

.research-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.research-item {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.highlighted {
  background-color: #f7e2ad;
  padding: 12px;
  margin: 0 -12px 0 -12px;
}

.thumbnail {
  width: 180px;
  height: 130px;
  background-color: #fff;
  object-fit: contain;
}

.content {
  flex: 1;
}

.content h3 {
  color: #242f6e;
  margin: 0 0 2px;
  font-size: 1.2rem;
}

.content h3 a {
  text-decoration: none;
  color: #242f6e;
}

.content p {
  color: #242f6e;
  margin: 0 0 10px;
  line-height: 1.3;
  font-size: 1.1rem;
}

.content .authors {
  margin: 0 0 2px;
}

.content .journal {
  font-style: italic;
  font-size: 0.9rem;
  margin: 0 0 8px;
}

.links {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 0;
  padding: 0;
}
.links li {
  display: inline;
  color: #c9179a;
  font-weight: bold;
  font-size: 1rem;
}

.links a {
  text-decoration: none;
  cursor: pointer;
  color: #c9179a;
}

.links a:hover {
  text-decoration: underline;
}
