.hero-section {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin: 40px 0;
}

.hero-text {
  flex: 0 0 65%;
}

.hero-text a {
  color: #242f6e;
}

.hero-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -4px;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin: 0 0 8px 0;
  color: #242f6e;
  font-weight: bold;
  text-align: center;
}

.hero-text p {
  font-size: 1.1rem;
  color: #242f6e;
  text-align: justify;
}

.social-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.social-links li {
  color: #242f6e;
  font-weight: bold;
}

.social-links a {
  color: #242f6e;
  text-decoration: none;
}

.profile-image {
  width: 100%;
}

.download-cv {
  font-family: inherit;
  background-color: #c71585;
  color: #f0e5c8;
  font-weight: bold;
  border: 3px solid #f0e5c8;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: -20px;
}

.download-cv:hover {
  background-color: #90106e;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    align-items: center;
  }

  .hero-text {
    flex: 0 0 100%;
  }

  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text p {
    font-size: 1rem;
    text-align: center;
  }

  .social-links {
    gap: 10px;
  }

  .hero-profile {
    width: 60%;
  }

  .download-cv {
    width: 80%;
    text-align: center;
  }
}
